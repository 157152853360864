export const COLORS = {
    dark: '#115959',
    theme: '#012837',
    regular: '#8DDACC',
    light: '#115959',
    // light: '#C6ECE5',
    white: '#8DDACC',
    black: '#000',
    gradient: ['#012837', '#8DDACC', '#C6ECE5'],
};
