import * as React from 'react';

const Ishan = () => {
    return (
        <div style={{
            backgroundColor: 'white', fontSize: ' calc(10px + 2vmin)',
            color: 'black', marginTop: '220px'
        }}>
           <h1>Ishan Ishan</h1>

        </div>
    )
}

export default Ishan;
